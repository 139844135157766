//
//
//
//
//
//


export default {
    name: 'IndexPage',
    head() {
        return {
            link: [{ rel: 'canonical', href: 'https://prep.yocket.com' }]
        };
    },
    created() {
        this.$nuxt.setLayout('default');
    }
};
